.site-footer {
  margin-top: 10px;
  background-color: #30323f;
  display: flex;
  flex-direction: column;
  .top {
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      img {
        width: 120px;
        height: 85px;
      }
      ul {
        flex: 1;
        padding-top: 4px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        margin-left: 6px;
        li {
          display: flex;
          font-size: 12px;
          color: #fff;
          text-align: left;
          img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }
        }
      }
    }
  }
  .bottom {
    height: 26px;
    background-color: #1a1d25;
    text-align: center;
    color: #fff;
    line-height: 26px;
    font-size: 12px;
  }
  .link {
      margin-top: 20px;
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      li {
          &:nth-of-type(1){
              border-right: 1px solid #fff;
              padding-right: 40px;
          }
        a {
          text-decoration: none;
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }
}
